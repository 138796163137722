(() => {
  const handleCarousels = () => {
    let $carouselEls: NodeListOf<HTMLElement>,
      $nonLoopedCarouseEls: HTMLElement[];

    // center aligns the content of the carousel when the combined content width of all the slides is less than the actual carousel
    const centerAlignCarouselContent = $el => {
      if ($el) {
        const $content = $el.querySelector(
          '.emu-carousel__content'
        ) as HTMLElement;

        if ($el.classList.contains('ba__modal-carousel')) {
          const $nav = $el.querySelector('.tns-nav');
          if ($nav.style.display === 'none') {
            $content.classList.add('u-center-align');
            $el.classList.add('u-carousel--disabled');
          } else {
            $content.classList.remove('u-center-align');
            $el.classList.remove('u-carousel--disabled');
          }

          $el.classList.add('u-carousel--visible');
          return;
        }

        if ($content) {
          if ($content.clientWidth < $el.clientWidth) {
            // adding center align class to align the carousel content to the center of the screen
            $content.classList.add('u-center-align');
            $el.classList.add('u-carousel--disabled');
            const $slideItems = $content.querySelectorAll(
              '.tns-item'
            ) as NodeListOf<HTMLElement>;
            if ($slideItems?.length > 1) {
              const gutter = parseInt(
                window.getComputedStyle($slideItems[0]).paddingRight,
                10
              );
              if (gutter && isNaN(gutter) !== true) {
                $content.style.marginLeft = `${
                  gutter / ($slideItems.length - 1)
                }px`;
              }
            }
          } else {
            $content.classList.remove('u-center-align');
            $el.classList.remove('u-carousel--disabled');
            $content.style.marginLeft = '';
          }
        }

        $el.classList.add('u-carousel--visible');
      }
    };

    const centerAlignNonLoopedCarousels = () => {
      if ($nonLoopedCarouseEls?.length) {
        $nonLoopedCarouseEls.forEach($el => {
          if ($el.classList.contains('ba__modal-carousel') !== true) {
            centerAlignCarouselContent($el);
          }
        });
      }
    };

    const appendEvents = () => {
      window.addEventListener('resize', () => {
        centerAlignNonLoopedCarousels();
      });

      // when a modal is open, if it has a carousel, making sure that the carousel content is center aligned if carousel can fit in one view port
      window.Bus.on('emu-modal:open', ({ id }) => {
        const $modal = document.querySelector(
          `[data-component="modal"][data-id="${id}"]`
        );

        // timer to make sure that the carousel settled
        setTimeout(() => {
          const $carousels = $modal?.querySelectorAll('.emu-carousel');
          $carousels?.forEach($el => {
            // making sure that the carousel calculations are all set well
            window.dispatchEvent(new Event('resize'));

            // timer to let the carousel settle
            setTimeout(() => {
              if ($el.getAttribute('data-loop') === 'false') {
                centerAlignCarouselContent($el);
              }
            }, 50);
          });
        }, 50);
      });

      // when a modal is close, if it has a carousel, making sure that the carousel is reset to slide 0
      window.Bus.on('emu-modal:close', ({ id }) => {
        const $modal = document.querySelector(
          `[data-component="modal"][data-id="${id}"]`
        );
        setTimeout(() => {
          const $carousels = $modal?.querySelectorAll('.emu-carousel');
          $carousels?.forEach($el => {
            // making sure that the first slide is shown always
            const carouselId = $el.id;
            if (carouselId && window._tnsInstances[carouselId]) {
              window._tnsInstances[carouselId]?.goTo(0);
            }
            $el.classList.remove('u-carousel--visible');
          });
        }, 50);
      });
    };

    const carouselSlideChange = $el => {
      const carouselId = $el.id;
      const curInst = window._tnsInstances[carouselId];

      // making sure that the first slide and first nav dot are active
      try {
        const info = curInst.getInfo();
        info?.slideItems[0].classList.add('js-slide-active');
        info?.navItems[0].classList.add('js-nav-active');
      } catch (e) {
        console.warn(e);
      }

      curInst?.events?.on('indexChanged', info => {
        if (info?.slideItems?.length) {
          const $slides = Array.from(info?.slideItems) as HTMLElement[];
          const $navItems = Array.from(info?.navItems) as HTMLElement[];
          try {
            $slides.forEach(($slide, i) => {
              $slide.classList.toggle(
                'js-slide-active',
                info.displayIndex - 1 === i
              );
            });

            $navItems.forEach(($slide, i) => {
              $slide.classList.toggle(
                'js-nav-active',
                info.displayIndex - 1 === i
              );
            });
          } catch (e) {
            $el.querySelectorAll('.tns-item').forEach($el => {
              $el.classList.add('js-slide-active');
            });
            console.warn(e);
          }
        }
      });
    };

    // adds an active class to the current slide and the navigation dot, making it possible to add styles to them
    const handleSlideChange = () => {
      if ($carouselEls.length) {
        $carouselEls.forEach(carousel => {
          carouselSlideChange(carousel);
        });
      }
    };

    const initVariables = () => {
      $carouselEls = document.querySelectorAll(
        '.emu-carousel'
      ) as NodeListOf<HTMLElement>;
      if ($carouselEls.length) {
        $nonLoopedCarouseEls = Array.from($carouselEls)?.filter(
          $el => $el.getAttribute('data-loop') === 'false'
        );
      }
    };

    const init = () => {
      centerAlignNonLoopedCarousels();
      handleSlideChange();
    };

    initVariables();
    appendEvents();
    init();
  };
  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', handleCarousels);
  } else {
    handleCarousels();
  }
})();
